<template>
  <footer>
    <p class="footer__title">Dawid Zalewski &copy; 2023</p>
  </footer>
</template>

<script>
export default{}
</script>

<style scoped lang="scss">
footer {
  position: static;
  bottom: 0;
  width: 100%;
  border-top: 1px solid white;
  background: black;
  .footer__title {
    color: white;
    text-align: center;
    padding: 16px 0;
  }
}

</style>